#image_welcome_page{
    width: 38%;
    padding-top:2rem;
    padding-bottom:3rem;
}

h1 {
    font-family: roboto-bold;
    color:rgb(100, 57, 0);
    font-size:55px;
}

p{
    font-family: roboto;
    color:black;
    font-size: 20px;
}

.foundus {
    padding-top: 5rem;
    padding-bottom: 4rem;
  }

  @media screen and (max-width: 774px) {
      #image_welcome_page{
      width:50%;
      }
  }

 #mail_contact{
     color:#e0860b;
     text-decoration: none;
     transition:0.2s;
 } 

 #mail_contact:hover{
     color:#d39a00;
 }