@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@font-face {
  font-family: roboto-black;
  src: url(/static/media/Roboto-Black.1a00134f.ttf);
}
@font-face {
  font-family: roboto-light;
  src: url(/static/media/Roboto-Light.d5c46b13.ttf);
}
@font-face {
  font-family: roboto;
  src: url(/static/media/Roboto-Regular.5673da52.ttf);
}
@font-face {
  font-family: roboto-bold;
  src: url(/static/media/Roboto-Bold.36b5bab5.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f7f5f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body{
    margin-left:30px;
    margin-right:30px;
}

#icon_spinner {
    width: 100px;
    height: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -45px;
}

.app-loading {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width:100%;
    margin:589px;
}


.app-loading .spinner {
    height: 200px;
    width: 200px;
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #e0860b;
}

@-webkit-keyframes rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    }
}

@keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    }
}

.page-link{
    color:rgb(100, 57, 0) !important;
    border-radius: 50px;
    font-weight:bold !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius:50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius:50px !important;
}

.page-item{
    color:rgb(100, 57, 0) !important;
    border-radius: 50px;
    padding:5px;
    font-weight:bold !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius:50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius:50px !important;
}

.page-item.active .page-link{
    color:white !important;
    background-color:rgb(100, 57, 0) !important;
    border-radius:50px;
    border-color:rgb(100, 57, 0) !important;
    font-weight:bold !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius:50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius:50px !important;
}

.card-deck{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#perso_image{
    width:65%;
}

@media screen and (max-width: 774px) {
    #perso_image{
        margin-top:100px;
    }
}

#noresults_image{
    margin-top:50px;
    width:50%;
}

@media screen and (max-width: 767px) {
    #noresults_image{
        margin-top:50px;
        width:65%;
    }
}

#aucuneannonce_image{
    width:55%;
    margin-top:50px;
}

@media screen and (max-width: 774px) {
    #aucuneannonce_image{
        width:65%;
    }
}

.style-1 {
    color: black;
}
.style-1:hover,
.style-1:focus {
    color: #2A6496;
    text-decoration: none;
}

.h-custom {
  height: 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.img-test{
  max-width : 100%;
  height: 100%;
  object-fit: contain;
  margin:auto;

}

.vertical-align-middle{
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.test {
  max-height : 200px;
  object-fit: cover;
  object-position : center;

}

.card{
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  box-shadow:0 0px 8px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

.card-img-top{
  box-shadow:0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 3px 0 rgba(0, 0, 0, 0.1)
}

.card-title{
  font-family: roboto-bold;
  color:rgb(100, 57, 0);
}

.card-affiliation{
  padding-left: 25px;
  font-size: 16px;
  color:rgb(100, 57, 0);
}


.text-muted{
  color: rgb(100, 57, 0) !important;
}

.card-text{
  font-family:roboto-light;
  font-size:18px;
  color: rgb(100, 57, 0);
}

.card:hover{
  -webkit-transform:scale(1.05);
          transform:scale(1.05);
  box-shadow:0 0px 8px 0 rgba(88, 53, 4, 0.5), 0 0px 20px 0 rgba(88, 53, 4, 0.5);

}

.container-cancer{
  padding-right: 20%;
  padding-left:20%;
}


.col-sm-6{
  color: rgb(100, 57, 0);
  padding: 7px;
  padding-top:10px;
}

#description{
  padding:5px;
}

button{
  padding: 6px;
}

.btn{
  width:35%;
  margin:5%;
}

.btn-info{
  background-color: #d56679 !important;
  border-color: #d56679 !important;
  opacity: 0.8 !important;
}
.btn-info:hover{
  background-color: #b94d5f !important;
  border-color: #b94d5f !important;
  opacity: 0.9 !important;

}
.col-lg-8{
  margin:auto;
}

@media screen and (max-width: 767px) {
  .container-cancer{
    padding-right:0%;
    padding-left:0%;
  }
  
  .btn{
    width:45%;
    margin:5%;
  }
   
  #titre_cancer{
    font-size:2rem;
    margin:auto;
  }
  .container-fluid{
    margin:0px !important;
    padding:0px !important;
  }
}

.box-add{
  background-color: #f2f3eb;
  border: solid 3px rgb(100, 57, 0);
  border-radius: 10px;
  padding: 1em;
  color:rgb(100, 57, 0);
}

.underline-long {
  border-bottom: solid 2px #e0860b;
  margin: -0.512em auto;
  margin-top: 1em;
  width: 5em;
}

.text-ad {
color: rgb(100, 57, 0);
}
.date-ad {
  color: #e0860b;
  }
.type-ad {
  color: rgb(100, 57, 0);
}
.cat-ad {
  margin-top: 1em;
  color: rgb(100, 57, 0);
}

#filter-bar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1em;
    row-gap: 1em;
    grid-column-gap: 10em;
    -webkit-column-gap: 10em;
            column-gap: 10em;
}

@media screen and (max-width: 760px) {
    #filter-bar {
        grid-template-columns: 1fr;
    }
    #category-tag{
        order: 1;
    }
    #category-select{
        order: 2;
    }
    #location-tag{
        order: 3;
    }
    #location-select{
        order: 4;
    }
}

#category-tag{
    text-align: center;
    font-size: 1.1em;
}
#location-tag{
    text-align: center;
    font-size: 1.1em;
}
.w-50 {
  width : 50%;
  height:auto;
}

html {
  font-family: 'Montserrat', Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
label{
  font-family:'Montserrat', Arial, sans-serif;
}
body {
  background: #F2F3EB;
}

button {
  overflow: visible;
}

button, select, label {
  text-transform: none;
}

button, input, select, textarea, label {
  color: rgb(100, 57, 0);
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

#container1 {
  border: solid 3px rgb(100, 57, 0);
  border-radius:0.25rem;
  max-width: 768px;
  margin: 60px auto;
  position: relative;
  margin-top:30px;
}

form {
  padding: 37.5px;
  margin: 50px 0;
}

h2 {
  color: rgb(100, 57, 0);
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;
  text-transform: uppercase;
}

.underline {
  border-bottom: solid 2px #e0860b;
  margin: -0.512em auto;
  width: 80px;
}

input[type='text'], [type='email'], select, textarea {
	background: none;
  border: none;
	border-bottom: solid 2px rgb(100, 57, 0);
	color: rgb(100, 57, 0);
	font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
	margin: 0em 0 1.875em 0;
	padding: 0 0 0.875em 0;
	width: 100%;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type='text']:hover, textarea:hover {
  cursor : text;
}

select:hover {
  cursor : pointer;
}


input[type='text']:focus, [type='email']:focus,[type='file']:focus, textarea:focus {
	outline: none;
	padding: 0 0 0.875em 0;
}

.inputfile{
  cursor:pointer;
}

.message {
	float: none;
}

select {
  background: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png') no-repeat right;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.subject {
  width: 100%;
}

textarea {
	line-height: 150%;
	height: 150px;
	resize: none;
  width: 100%;
}

::-webkit-input-placeholder {
	color: rgb(100, 57, 0);
}

:-moz-placeholder { 
	color: rgb(100, 57, 0);
	opacity: 1;
}

::-moz-placeholder {
	color: rgb(100, 57, 0);
	opacity: 1;
}

:-ms-input-placeholder {
	color: rgb(100, 57, 0);
}

#form_button {
  border-radius:0.25rem;
  float:none;
  background: none;
  border: solid 2px rgb(100, 57, 0);
  color: rgb(100, 57, 0);
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	transition: all 0.3s;
}

#form_button:hover {
  background: rgb(100, 57, 0);
  color: #F2F3EB;
}

@media screen and (max-width: 768px) {
  #container1 {
    margin: 20px auto;
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 26px;
  }
  
  .underline {
    width: 68px;
  }
  
  #form_button {
    padding: 15px 25px;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 18px;
  }
  
  .icon {
    height: 35px;
    width: 35px;
  }
  
  .underline {
    width: 53px;
  }
  
  input[type='text'], [type='email'], [type='file'], select, textarea {
    font-size: 0.875em;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn10 {
  background:inherit;
  border: solid 2px rgb(100, 57, 0);
  border-radius:0.25rem;
  color: rgb(100, 57, 0);
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 0.875em ;
  margin:0%;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.btn10:hover{
  background: rgb(100, 57, 0);
  color: #F2F3EB;
  cursor:pointer;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#palc_option{
  background:none !important;
  padding:1rem !important;
  background-color:#F2F3EB !important;
}

#palc_option:hover{
  background:rgb(100, 57, 0)!important;
  color: #F2F3EB !important;
}


#icon_spinner {
    width: 100px;
    height: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -45px;
}

.app-loading {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.app-loading .spinner {
    height: 200px;
    width: 200px;
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #e0860b;
}

@-webkit-keyframes rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    }
}

@keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    }
}

#image_welcome_page{
    width: 38%;
    padding-top:2rem;
    padding-bottom:3rem;
}

h1 {
    font-family: roboto-bold;
    color:rgb(100, 57, 0);
    font-size:55px;
}

p{
    font-family: roboto;
    color:black;
    font-size: 20px;
}

.foundus {
    padding-top: 5rem;
    padding-bottom: 4rem;
  }

  @media screen and (max-width: 774px) {
      #image_welcome_page{
      width:50%;
      }
  }

 #mail_contact{
     color:#e0860b;
     text-decoration: none;
     -webkit-transition:0.2s;
     transition:0.2s;
 } 

 #mail_contact:hover{
     color:#d39a00;
 }

#beer_text {
	font-family: 'roboto';
	color: white;
	letter-spacing: 15px;
	font-size: 30px;
	position: absolute;
	top: 195px;
    left: 370px;
    
}

#glass {
	width: 70px;
	height: 90px;
	background: transparent;
	border: solid 3.5px white;
	border-top: none;
	border-radius: 0 0 10px 10px;
	position: absolute;
	top: 200px;
	left: 200px;
	overflow: hidden;
	-webkit-transform: rotate(-20deg);
	        transform: rotate(-20deg);
    -webkit-animation: glass 10s infinite;
    box-sizing:unset;
}
	
#poignet {
		width: 30px;
		height: 55px;
		background: transparent;
		border: solid 3.5px white;
		position: absolute;
		top: 192px;
		left: 270px;
		border-radius: 0 16px 16px 0;
		border-left: none;
		-webkit-transform: rotate(-20deg);
		        transform: rotate(-20deg);
	-webkit-animation: poignet 10s infinite;
}

#beer {
	width: 58px;
	height: 84px;
	background: transparent;
	position: absolute;
	left: 6px;
	top: 0px;
	border-radius: 0 0 5px 5px;
	overflow: hidden;}
	
	#beer::before {
		content: "";
		width: 250px;
		height: 250px;
		background: #E8B504;
		position: absolute;
		top: 83px;
		left: -190px;
		-webkit-transform: rotate(40deg);
		        transform: rotate(40deg);
		-webkit-animation: beer 10s infinite;
	}

#mousse_1 {
	position: absolute;
	top: 192px;
	left: 198px;
	z-index: 4;}
	
	#mousse_1::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_1::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 10px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_2 {
	position: absolute;
	top: 192px;
	left: 212px;
	z-index: 4;}
	
	#mousse_2::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_2::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: -5px;
		left: 10px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_3 {
	position: absolute;
	top: 192px;
	left: 228px;
	z-index: 4;}
	
	#mousse_3::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_3::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 10px;
		left: 15px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_4 {
	position: absolute;
	top: 192px;
	left: 242px;
	z-index: 4;}
	
	#mousse_4::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_4::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 10px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_5 {
	position: absolute;
	top: 192px;
	left: 257px;
	z-index: 4;
	-webkit-animation: mousse 10s infinite;}
	
	#mousse_5::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
	}

#mousse_volante {
	position: absolute;
	top: 185px;
	left: 212px;
	z-index: 4;}
	
	#mousse_volante::before {
		content: "";
		width: 12px;
		height: 12px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_volante 10s infinite;
	}
	
	#mousse_volante::after {
		content: "";
		width: 8px;
		height: 8px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 15px;
		left: 40px;
		-webkit-animation: mousse_volante 10s infinite;
	}

#mousse_interieur {
	position: absolute;
	top: 255px;
	left: 218px;
	z-index: 4;
	opacity: 0.9;}
	
	#mousse_interieur::before {
		content: "";
		width: 6px;
		height: 6px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur::after {
		content: "";
		width: 4px;
		height: 4px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 15px;
		left: 30px;
		-webkit-animation: mousse_interieur 5s infinite;
	}

#mousse_interieur_2 {
	position: absolute;
	top: 235px;
	left: 225px;
	z-index: 4;
	opacity: 0.9;}
	
	#mousse_interieur_2::before {
		content: "";
		width: 7px;
		height: 7px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur_2::after {
		content: "";
		width: 3px;
		height: 3px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 17px;
		-webkit-animation: mousse_interieur 5s infinite;
	}


#mousse_interieur_3 {
	position: absolute;
	top: 245px;
	left: 232px;
	z-index: 4;
	opacity: 0.9;}
	
	#mousse_interieur_3::before {
		content: "";
		width: 9px;
		height: 9px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur_3::after {
		content: "";
		width: 5px;
		height: 5px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 8px;
		left: 12px;
		-webkit-animation: mousse_interieur 5s infinite;
	}

#mousse_interieur_4 {
	position: absolute;
	top: 241px;
	left: 238px;
	z-index: 4;
    opacity: 0.9;}
    
	#mousse_interieur_4::before {
		content: "";
		width: 2px;
		height: 2px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur_4::after {
		content: "";
		width: 5.5px;
		height: 5.5px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 28px;
		left: 2px;
		-webkit-animation: mousse_interieur 5s infinite;
	}

/* ANIMATIONS */

@-webkit-keyframes beer {
	0% {
		-webkit-transform: translateY(-5);
		        transform: translateY(-5);
	}
	17% {
		-webkit-transform: translateY(-82px);
		        transform: translateY(-82px);	
	}
	100% {
		-webkit-transform: translateY(-82px);
		        transform: translateY(-82px);
	}
}

@-webkit-keyframes glass {
	0% {
		-webkit-transform: rotate(-20deg);
		        transform: rotate(-20deg);
	}
	
	17% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
}


@-webkit-keyframes poignet {
	0% {
		-webkit-transform: rotate(-20deg);
		        transform: rotate(-20deg);
	}
	17% {
		-webkit-transform: rotate(0deg) translateX(5px) translateY(20px);
		        transform: rotate(0deg) translateX(5px) translateY(20px);
	}
	
	100% {
		-webkit-transform: rotate(0deg) translateX(5px) translateY(20px);
		        transform: rotate(0deg) translateX(5px) translateY(20px);
	}	
}

@-webkit-keyframes mousse {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	10% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	20% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes mousse_volante {
	0% {
		-webkit-transform: scale(0)
			translateY(0px);
		        transform: scale(0)
			translateY(0px);
	}
	10% {
		-webkit-transform: scale(0)
			translateY(0px);
		        transform: scale(0)
			translateY(0px);
	}
	20% {
		-webkit-transform: scale(1) translateY(-20px);
		        transform: scale(1) translateY(-20px);
	}
	
	40% {
		opacity: 0;
	}
	
	100% {
		-webkit-transform: scale(1) translateY(-80px);
		        transform: scale(1) translateY(-80px);
		opacity: 0;
	}
}

@-webkit-keyframes mousse_interieur {
	0% {
		-webkit-transform: scale(0)
			translateY(0px);
		        transform: scale(0)
			translateY(0px);
	}
	20% {
		-webkit-transform: scale(0)
			translateY(0px);
		        transform: scale(0)
			translateY(0px);
	}
	40% {
		-webkit-transform: scale(1) translateY(-60px);
		        transform: scale(1) translateY(-60px);
	}
	
	50% {
		opacity: 0;
	}
	
	100% {
		-webkit-transform: scale(1) translateY(-60px);
		        transform: scale(1) translateY(-60px);
		opacity: 0;
	}
}


.btn1 {
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 300px;
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
	color: rgb(100, 57, 0) !important;
  }

.btn-xl {
    padding: 1rem 2rem;
}
  
.btn-primary1 {
    color:rgb(100, 57, 0) !important;
    background-color: #F9B804;
    border-color: rgb(100, 57, 0);
    border:solid;
	border-color:transparent;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

  
.btn-primary1:active,   
.btn-primary1:focus,   
.btn-primary1:hover {
    color:#F9B804 !important;
    background-color: transparent;
    border:solid;
    border-color:#F9B804;}

#animation{
    width: 100px;
    height: 90px;
    position: absolute;
    top: 15%;
    left: 28%;
    margin-left: -50px;
    margin-top: -45px;
  }



@media screen and (max-width: 767px) {
	#colonne{
		padding-bottom:50px !important;
	}
    .btn1 {
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        border-radius: 300px;
        font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
        font-size: 12px;
        color: #f7f5f4;
        margin:auto;
    }
    
    .btn-primary1 {
        background-color: #F9B804;
        border-color:rgb(100, 57, 0);
        border:solid;
        border-color:transparent;
    }

    .btn-primary1:active,   
    .btn-primary1:focus,   
    .btn-primary1:hover {
    color:#F9B804 !important;
    background-color: transparent;
    border:solid;
    border-color:#F9B804;}

    #animation{
        width: 100px;
    height: 90px;
    position: absolute;
    top: 13%;
    left: -30%;

	}
	
	#boutons{
		padding:0px !important;
	}

	#beer_text{
		font-size:22px !important;
	}
  }

#fond{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%; 
    height: 100%;
    background: rgb(100, 57, 0) ;
}


#boutons{
    margin-top:400px;
}
.hover-pointer:hover{
  cursor : pointer;
}

.navbar{
  background-color: rgb(100, 57, 0);
}

#searchBar{
  border-radius:10px;
}

#searchbutton{
  border-radius:10px;
  color:#e0860b;
  border:#e0860b solid 1px;
  background-color:transparent;
  text-decoration:none;
}

#searchbutton:hover{
  color:white;
  border:solid 1px transparent !important;
  background-color: #facf57;
}

.search {
	display: inline-block;
	position: relative;
	height: 35px;
	width: 35px;
	box-sizing: border-box;
	border: 3px solid #facf57;
	border-radius: 25px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: text;
  padding-left:10px;
  padding-top:1.5px;
}
	
	.search:after {
		content: "";
		position: absolute;
		width: 3px;
		height: 20px;
		right: -5px;
		top: 21px;
		background: #facf57;
		border-radius: 3px;
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}
	
	.search.active,
	.search:hover {
		width: 200px;
		margin-right: 0px;}
		
		.search:hover:after {
			height: 0px;	
		}

	
	.search input {
		width: 100%;
		border: none;
		box-sizing: border-box;
		font-family: Helvetica;
		font-size: 15px;
    color: white;
    opacity:0.7;
		background: transparent;
		outline-width: 0px;
	}

.navbar-brand{
	cursor: pointer;
  color:white !important;
}

.navbar-brand:hover{
	cursor: pointer;
  color:white !important;
}

@media screen and (max-width: 1100px) {
	.cntr{
		margin-bottom:8px !important;
		margin-top:5px !important;
	}
}
