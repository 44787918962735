.w-50 {
  width : 50%;
  height:auto;
}





@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

html {
  font-family: 'Montserrat', Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
label{
  font-family:'Montserrat', Arial, sans-serif;
}
body {
  background: #F2F3EB;
}

button {
  overflow: visible;
}

button, select, label {
  text-transform: none;
}

button, input, select, textarea, label {
  color: rgb(100, 57, 0);
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

#container1 {
  border: solid 3px rgb(100, 57, 0);
  border-radius:0.25rem;
  max-width: 768px;
  margin: 60px auto;
  position: relative;
  margin-top:30px;
}

form {
  padding: 37.5px;
  margin: 50px 0;
}

h2 {
  color: rgb(100, 57, 0);
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;
  text-transform: uppercase;
}

.underline {
  border-bottom: solid 2px #e0860b;
  margin: -0.512em auto;
  width: 80px;
}

input[type='text'], [type='email'], select, textarea {
	background: none;
  border: none;
	border-bottom: solid 2px rgb(100, 57, 0);
	color: rgb(100, 57, 0);
	font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 1px;
	margin: 0em 0 1.875em 0;
	padding: 0 0 0.875em 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
  transition: all 0.3s;
}

input[type='text']:hover, textarea:hover {
  cursor : text;
}

select:hover {
  cursor : pointer;
}


input[type='text']:focus, [type='email']:focus,[type='file']:focus, textarea:focus {
	outline: none;
	padding: 0 0 0.875em 0;
}

.inputfile{
  cursor:pointer;
}

.message {
	float: none;
}

select {
  background: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png') no-repeat right;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.subject {
  width: 100%;
}

textarea {
	line-height: 150%;
	height: 150px;
	resize: none;
  width: 100%;
}

::-webkit-input-placeholder {
	color: rgb(100, 57, 0);
}

:-moz-placeholder { 
	color: rgb(100, 57, 0);
	opacity: 1;
}

::-moz-placeholder {
	color: rgb(100, 57, 0);
	opacity: 1;
}

:-ms-input-placeholder {
	color: rgb(100, 57, 0);
}

#form_button {
  border-radius:0.25rem;
  float:none;
  background: none;
  border: solid 2px rgb(100, 57, 0);
  color: rgb(100, 57, 0);
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

#form_button:hover {
  background: rgb(100, 57, 0);
  color: #F2F3EB;
}

@media screen and (max-width: 768px) {
  #container1 {
    margin: 20px auto;
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 26px;
  }
  
  .underline {
    width: 68px;
  }
  
  #form_button {
    padding: 15px 25px;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 18px;
  }
  
  .icon {
    height: 35px;
    width: 35px;
  }
  
  .underline {
    width: 53px;
  }
  
  input[type='text'], [type='email'], [type='file'], select, textarea {
    font-size: 0.875em;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn10 {
  background:inherit;
  border: solid 2px rgb(100, 57, 0);
  border-radius:0.25rem;
  color: rgb(100, 57, 0);
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 0.875em ;
  margin:0%;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.btn10:hover{
  background: rgb(100, 57, 0);
  color: #F2F3EB;
  cursor:pointer;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#palc_option{
  background:none !important;
  padding:1rem !important;
  background-color:#F2F3EB !important;
}

#palc_option:hover{
  background:rgb(100, 57, 0)!important;
  color: #F2F3EB !important;
}
