.style-1 {
    color: black;
}
.style-1:hover,
.style-1:focus {
    color: #2A6496;
    text-decoration: none;
}

.h-custom {
  height: 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.img-test{
  max-width : 100%;
  height: 100%;
  object-fit: contain;
  margin:auto;

}

.vertical-align-middle{
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.test {
  max-height : 200px;
  object-fit: cover;
  object-position : center;

}

.card{
  transition: transform .2s;
  box-shadow:0 0px 8px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

.card-img-top{
  box-shadow:0 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 3px 0 rgba(0, 0, 0, 0.1)
}

.card-title{
  font-family: roboto-bold;
  color:rgb(100, 57, 0);
}

.card-affiliation{
  padding-left: 25px;
  font-size: 16px;
  color:rgb(100, 57, 0);
}


.text-muted{
  color: rgb(100, 57, 0) !important;
}

.card-text{
  font-family:roboto-light;
  font-size:18px;
  color: rgb(100, 57, 0);
}

.card:hover{
  transform:scale(1.05);
  box-shadow:0 0px 8px 0 rgba(88, 53, 4, 0.5), 0 0px 20px 0 rgba(88, 53, 4, 0.5);

}

.container-cancer{
  padding-right: 20%;
  padding-left:20%;
}


.col-sm-6{
  color: rgb(100, 57, 0);
  padding: 7px;
  padding-top:10px;
}

#description{
  padding:5px;
}

button{
  padding: 6px;
}

.btn{
  width:35%;
  margin:5%;
}

.btn-info{
  background-color: #d56679 !important;
  border-color: #d56679 !important;
  opacity: 0.8 !important;
}
.btn-info:hover{
  background-color: #b94d5f !important;
  border-color: #b94d5f !important;
  opacity: 0.9 !important;

}
.col-lg-8{
  margin:auto;
}

@media screen and (max-width: 767px) {
  .container-cancer{
    padding-right:0%;
    padding-left:0%;
  }
  
  .btn{
    width:45%;
    margin:5%;
  }
   
  #titre_cancer{
    font-size:2rem;
    margin:auto;
  }
  .container-fluid{
    margin:0px !important;
    padding:0px !important;
  }
}

.box-add{
  background-color: #f2f3eb;
  border: solid 3px rgb(100, 57, 0);
  border-radius: 10px;
  padding: 1em;
  color:rgb(100, 57, 0);
}

.underline-long {
  border-bottom: solid 2px #e0860b;
  margin: -0.512em auto;
  margin-top: 1em;
  width: 5em;
}

.text-ad {
color: rgb(100, 57, 0);
}
.date-ad {
  color: #e0860b;
  }
.type-ad {
  color: rgb(100, 57, 0);
}
.cat-ad {
  margin-top: 1em;
  color: rgb(100, 57, 0);
}
