#filter-bar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
    column-gap: 10em;
}

@media screen and (max-width: 760px) {
    #filter-bar {
        grid-template-columns: 1fr;
    }
    #category-tag{
        order: 1;
    }
    #category-select{
        order: 2;
    }
    #location-tag{
        order: 3;
    }
    #location-select{
        order: 4;
    }
}

#category-tag{
    text-align: center;
    font-size: 1.1em;
}
#location-tag{
    text-align: center;
    font-size: 1.1em;
}