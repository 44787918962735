body{
    margin-left:30px;
    margin-right:30px;
}

#icon_spinner {
    width: 100px;
    height: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -45px;
}

.app-loading {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width:100%;
    margin:589px;
}


.app-loading .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #e0860b;
}

@keyframes rotate {
    100% {
    transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    }
}

.page-link{
    color:rgb(100, 57, 0) !important;
    border-radius: 50px;
    font-weight:bold !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius:50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius:50px !important;
}

.page-item{
    color:rgb(100, 57, 0) !important;
    border-radius: 50px;
    padding:5px;
    font-weight:bold !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius:50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius:50px !important;
}

.page-item.active .page-link{
    color:white !important;
    background-color:rgb(100, 57, 0) !important;
    border-radius:50px;
    border-color:rgb(100, 57, 0) !important;
    font-weight:bold !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius:50px !important;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius:50px !important;
}

.card-deck{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

#perso_image{
    width:65%;
}

@media screen and (max-width: 774px) {
    #perso_image{
        margin-top:100px;
    }
}

#noresults_image{
    margin-top:50px;
    width:50%;
}

@media screen and (max-width: 767px) {
    #noresults_image{
        margin-top:50px;
        width:65%;
    }
}

#aucuneannonce_image{
    width:55%;
    margin-top:50px;
}

@media screen and (max-width: 774px) {
    #aucuneannonce_image{
        width:65%;
    }
}
