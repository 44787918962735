
#beer_text {
	font-family: 'roboto';
	color: white;
	letter-spacing: 15px;
	font-size: 30px;
	position: absolute;
	top: 195px;
    left: 370px;
    
}

#glass {
	width: 70px;
	height: 90px;
	background: transparent;
	border: solid 3.5px white;
	border-top: none;
	border-radius: 0 0 10px 10px;
	position: absolute;
	top: 200px;
	left: 200px;
	overflow: hidden;
	transform: rotate(-20deg);
    -webkit-animation: glass 10s infinite;
    box-sizing:unset;
}
	
#poignet {
		width: 30px;
		height: 55px;
		background: transparent;
		border: solid 3.5px white;
		position: absolute;
		top: 192px;
		left: 270px;
		border-radius: 0 16px 16px 0;
		border-left: none;
		transform: rotate(-20deg);
	-webkit-animation: poignet 10s infinite;
}

#beer {
	width: 58px;
	height: 84px;
	background: transparent;
	position: absolute;
	left: 6px;
	top: 0px;
	border-radius: 0 0 5px 5px;
	overflow: hidden;}
	
	#beer::before {
		content: "";
		width: 250px;
		height: 250px;
		background: #E8B504;
		position: absolute;
		top: 83px;
		left: -190px;
		transform: rotate(40deg);
		-webkit-animation: beer 10s infinite;
	}

#mousse_1 {
	position: absolute;
	top: 192px;
	left: 198px;
	z-index: 4;}
	
	#mousse_1::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_1::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 10px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_2 {
	position: absolute;
	top: 192px;
	left: 212px;
	z-index: 4;}
	
	#mousse_2::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_2::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: -5px;
		left: 10px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_3 {
	position: absolute;
	top: 192px;
	left: 228px;
	z-index: 4;}
	
	#mousse_3::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_3::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 10px;
		left: 15px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_4 {
	position: absolute;
	top: 192px;
	left: 242px;
	z-index: 4;}
	
	#mousse_4::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse 10s infinite;
	}
	
	#mousse_4::after {
		content: "";
		width: 17px;
		height: 17px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 10px;
		-webkit-animation: mousse 10s infinite;
	}

#mousse_5 {
	position: absolute;
	top: 192px;
	left: 257px;
	z-index: 4;
	-webkit-animation: mousse 10s infinite;}
	
	#mousse_5::before {
		content: "";
		width: 20px;
		height: 20px;
		background: white;
		border-radius: 50%;
		position: absolute;
	}

#mousse_volante {
	position: absolute;
	top: 185px;
	left: 212px;
	z-index: 4;}
	
	#mousse_volante::before {
		content: "";
		width: 12px;
		height: 12px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_volante 10s infinite;
	}
	
	#mousse_volante::after {
		content: "";
		width: 8px;
		height: 8px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 15px;
		left: 40px;
		-webkit-animation: mousse_volante 10s infinite;
	}

#mousse_interieur {
	position: absolute;
	top: 255px;
	left: 218px;
	z-index: 4;
	opacity: 0.9;}
	
	#mousse_interieur::before {
		content: "";
		width: 6px;
		height: 6px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur::after {
		content: "";
		width: 4px;
		height: 4px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 15px;
		left: 30px;
		-webkit-animation: mousse_interieur 5s infinite;
	}

#mousse_interieur_2 {
	position: absolute;
	top: 235px;
	left: 225px;
	z-index: 4;
	opacity: 0.9;}
	
	#mousse_interieur_2::before {
		content: "";
		width: 7px;
		height: 7px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur_2::after {
		content: "";
		width: 3px;
		height: 3px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 17px;
		-webkit-animation: mousse_interieur 5s infinite;
	}


#mousse_interieur_3 {
	position: absolute;
	top: 245px;
	left: 232px;
	z-index: 4;
	opacity: 0.9;}
	
	#mousse_interieur_3::before {
		content: "";
		width: 9px;
		height: 9px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur_3::after {
		content: "";
		width: 5px;
		height: 5px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 8px;
		left: 12px;
		-webkit-animation: mousse_interieur 5s infinite;
	}

#mousse_interieur_4 {
	position: absolute;
	top: 241px;
	left: 238px;
	z-index: 4;
    opacity: 0.9;}
    
	#mousse_interieur_4::before {
		content: "";
		width: 2px;
		height: 2px;
		background: white;
		border-radius: 50%;
		position: absolute;
		-webkit-animation: mousse_interieur 10s infinite;
	}
	
	#mousse_interieur_4::after {
		content: "";
		width: 5.5px;
		height: 5.5px;
		background: white;
		border-radius: 50%;
		position: absolute;
		top: 28px;
		left: 2px;
		-webkit-animation: mousse_interieur 5s infinite;
	}

/* ANIMATIONS */

@-webkit-keyframes beer {
	0% {
		transform: translateY(-5);
	}
	17% {
		transform: translateY(-82px);	
	}
	100% {
		transform: translateY(-82px);
	}
}

@-webkit-keyframes glass {
	0% {
		transform: rotate(-20deg);
	}
	
	17% {
		transform: rotate(0deg);
	}
	
	100% {
		transform: rotate(0deg);
	}
}


@-webkit-keyframes poignet {
	0% {
		transform: rotate(-20deg);
	}
	17% {
		transform: rotate(0deg) translateX(5px) translateY(20px);
	}
	
	100% {
		transform: rotate(0deg) translateX(5px) translateY(20px);
	}	
}

@-webkit-keyframes mousse {
	0% {
		transform: scale(0);
	}
	10% {
		transform: scale(0);
	}
	20% {
		transform: scale(1);
	}
}

@-webkit-keyframes mousse_volante {
	0% {
		transform: scale(0)
			translateY(0px);
	}
	10% {
		transform: scale(0)
			translateY(0px);
	}
	20% {
		transform: scale(1) translateY(-20px);
	}
	
	40% {
		opacity: 0;
	}
	
	100% {
		transform: scale(1) translateY(-80px);
		opacity: 0;
	}
}

@-webkit-keyframes mousse_interieur {
	0% {
		transform: scale(0)
			translateY(0px);
	}
	20% {
		transform: scale(0)
			translateY(0px);
	}
	40% {
		transform: scale(1) translateY(-60px);
	}
	
	50% {
		opacity: 0;
	}
	
	100% {
		transform: scale(1) translateY(-60px);
		opacity: 0;
	}
}


.btn1 {
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 300px;
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
	color: rgb(100, 57, 0) !important;
  }

.btn-xl {
    padding: 1rem 2rem;
}
  
.btn-primary1 {
    color:rgb(100, 57, 0) !important;
    background-color: #F9B804;
    border-color: rgb(100, 57, 0);
    border:solid;
	border-color:transparent;
	transition: 0.25s;
}

  
.btn-primary1:active,   
.btn-primary1:focus,   
.btn-primary1:hover {
    color:#F9B804 !important;
    background-color: transparent;
    border:solid;
    border-color:#F9B804;}

#animation{
    width: 100px;
    height: 90px;
    position: absolute;
    top: 15%;
    left: 28%;
    margin-left: -50px;
    margin-top: -45px;
  }



@media screen and (max-width: 767px) {
	#colonne{
		padding-bottom:50px !important;
	}
    .btn1 {
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        border-radius: 300px;
        font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
        font-size: 12px;
        color: #f7f5f4;
        margin:auto;
    }
    
    .btn-primary1 {
        background-color: #F9B804;
        border-color:rgb(100, 57, 0);
        border:solid;
        border-color:transparent;
    }

    .btn-primary1:active,   
    .btn-primary1:focus,   
    .btn-primary1:hover {
    color:#F9B804 !important;
    background-color: transparent;
    border:solid;
    border-color:#F9B804;}

    #animation{
        width: 100px;
    height: 90px;
    position: absolute;
    top: 13%;
    left: -30%;

	}
	
	#boutons{
		padding:0px !important;
	}

	#beer_text{
		font-size:22px !important;
	}
  }

#fond{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%; 
    height: 100%;
    background: rgb(100, 57, 0) ;
}


#boutons{
    margin-top:400px;
}