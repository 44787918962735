@font-face {
  font-family: roboto-black;
  src: url(./files/Roboto-Black.ttf);
}
@font-face {
  font-family: roboto-light;
  src: url(./files/Roboto-Light.ttf);
}
@font-face {
  font-family: roboto;
  src: url(./files/Roboto-Regular.ttf);
}
@font-face {
  font-family: roboto-bold;
  src: url(./files/Roboto-Bold.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f7f5f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
