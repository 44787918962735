
#icon_spinner {
    width: 100px;
    height: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -45px;
}

.app-loading {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.app-loading .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #e0860b;
}

@keyframes rotate {
    100% {
    transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    }

    50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
    }

    100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
    }
}
